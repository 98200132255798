<template>
  <main>
    <content-page-header>
      <common-page-title title="パスワードリセット" />
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <section>
          <common-block-title title="パスワードの変更" sub-title="" />
          <form id="editUserForm" class="edit-form">
            <label class="form-function">
              <div class="form-input-label">新パスワード</div>
              <div class="with-icon w-full">
                <input
                  :type="state.newPasswordCheck ? 'text' : 'password'"
                  placeholder="新パスワード"
                  v-model="state.new_password"
                />
                <span
                  class="input-icon"
                  @click="state.newPasswordCheck = !state.newPasswordCheck"
                >
                  <icon
                    v-if="!state.newPasswordCheck"
                    name="input-eye-icon"
                    class="icon"
                  />
                  <icon v-else name="input-eye-icon-active" class="icon" />
                </span>
                <div
                  class="error-message"
                  v-for="(error, index) in state.errorEmail"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
                <div
                  class="error-message"
                  v-for="(error, index) in state.errorToken"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
                <div
                  class="error-message"
                  v-for="(error, index) in state.errorPassword"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
                <div class="error-message" v-if="state.errorMessage != ''">
                  <icon name="error" class="icon" />{{ state.errorMessage }}
                </div>
              </div>
            </label>
          </form>
        </section>

        <div class="submit-area">
          <button
            class="submit-area-button submit-area-button--save"
            :disabled="state.new_password == ''"
            @click="submit"
          >
            Send
          </button>
        </div>
      </panel-body>
    </div>
  </main>
</template>

<script>
import { defineComponent, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import http from "@/utils/http";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "resetPasswordSend",

  components: {
    contentPageHeader,
    CommonPageTitle,
    PanelBody,
    CommonBlockTitle,
  },
  props: [],
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      new_password: "",
      newPasswordCheck: false,
      errorMessage: "",
      errorEmail: "",
      errorPassword: "",
      errorToken: "",
    });
    const isLogin = computed(() => store.state.user.isLogin);

    window.scrollTo(0, 0);

    const submit = () => {
      http
        .post("/api/passwords/reset", {
          email: route.query.email,
          token: route.query.token,
          password: state.new_password,
        })
        .then((res) => {
          console.log(res.data);
          router.push("/reset-password/input/complete");
        })
        .catch((error) => {
          console.log("エラー:", error.response.data);
          if (error.config && error.response && error.response.status === 400) {
            state.errorEmail = error.response.data.errors.email;
            state.errorPassword = error.response.data.errors.password;
            state.errorToken = error.response.data.errors.token;
          } else if (
            error.config &&
            error.response &&
            error.response.status === 500
          ) {
            state.errorMessage = error.response.data.message;
          }
        });
    };

    onMounted(async () => {
      if (isLogin.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      store,
      state,
      submit,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.content-header) {
  height: auto;
  padding: 100px 100px 200px;
  background-size: cover;
  @include mq(sm) {
    padding: 100px 20px 200px;
  }
}
.content-body {
  position: relative;
  margin-top: -100px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.edit-form {
  padding: 65px 0;
  border-bottom: 0.5px solid #0f0f11;
}
</style>